import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TotalActiveFiltersPipe} from './total-active-filters.pipe';
import {NotFoundOnExportPipe} from './not-found-on-export.pipe';
import {TrimPipe} from './trim.pipe';

@NgModule({
  declarations: [
    TotalActiveFiltersPipe,
    NotFoundOnExportPipe,
    TrimPipe
  ],
  imports: [CommonModule],
  exports: [
    TotalActiveFiltersPipe,
    NotFoundOnExportPipe,
    TrimPipe
  ]
})
export class PipeModule {
}
