import {Pipe, PipeTransform} from '@angular/core';
import {FinalSong} from '../interfaces/final-song';
import {SongsNotFound} from '../interfaces/playlist-return-object';

@Pipe({
  name: 'notFoundOnExport',
  standalone: false
})
export class NotFoundOnExportPipe implements PipeTransform {
  transform(song: FinalSong, songsNotFound: SongsNotFound[]): boolean {
    return !!songsNotFound.find((s) => song.artist === s.artist);
  }
}
