import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'trim',
  standalone: false
})
export class TrimPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    return value.trim();
  }
}
