import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'totalActiveFilters',
  standalone: false
})
export class TotalActiveFiltersPipe implements PipeTransform {
  transform(totalActiveFilters: number, title: string, lyricsContains?: string | null): number {
    if (title === 'Lyrical Theme' && lyricsContains) {
      return totalActiveFilters + 1;
    }
    return totalActiveFilters;
  }
}
